import React from 'react';
import logo from './logo.svg';
import './App.css';

const GALLERY_URL = 'https://photos.google.com/share/AF1QipNceqf1UaM4_Bhya4XESdXB25IW-q5jn7WM1FktjJ5l93TVq-CZ7F2NEUPifyq5gA?key=LXp3VEZHTzkzaDF6V3JnU0czYWEtaHpib3NIa3JR';

function App() {
  return (
    <div className="App">
      <div className="header">
        <img src={logo} className="App-logo" alt="snowdome!" />
        <h1>
          Welcome to the Tahoe Snow Dome!
        </h1>
      </div>
      <p>
        <strong>Location</strong>: Between Meyers and South Lake Tahoe, the house is 10 minutes from South Lake Tahoe and a short drive to Heavenly or Sierra at Tahoe
      </p>
      <p>
        <strong>Accommodations</strong>: The Snowdome has <strong>3 bedrooms and 2 baths</strong> and sleeps up to 6 guests (8 with the fold out couch)
      </p>
      <p>
        <strong>Amenities:</strong> The property has high speed WiFi, two parking spots, and forced air heating. Cozy up next to the wood burning stove, jam out on the guitar and piano, or play some board games that are all available in the home. The Snowdome is surrounded by Conservancy land and you can enjoy sledding and snowball fights in the winter or a barbecue in the summers.
      </p>
      <div className="photos">
        <div className="photos-container">
          <a target="_blank" rel="noopener noreferrer" href={GALLERY_URL}>
            <img src="/images/dome1.jpg" alt="outside" />
            <img src="/images/dome2.jpg" alt="inside" />
          </a>
        </div>
        <div>
          <a className="button" target="_blank" rel="noopener noreferrer" href={GALLERY_URL}>
            <span className="text">View more photos</span>
            <span className="eyes">👀</span>
          </a>
        </div>
      </div>
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7388.571633466333!2d-120.0074481415849!3d38.866533077752635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80998e769e0db985%3A0x34beed4ebfb37acb!2sPebble%20Beach%20Rd%2C%20California%2096150!5e0!3m2!1sen!2sus!4v1647114471882!5m2!1sen!2sus" allowFullScreen loading="lazy"></iframe>
      </div>
    </div>
  );
}

export default App;
